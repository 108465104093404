@use "sass:list";
/*
 a rainbow of 16 colors (0-15), available as fg, border, bg, and var()

 .r{x} — foreground color
 .bg-r{x} — background color
 .b--r{x} - border color

 .r1
 .bg-r1
 .b--r1
*/

$colors: (
  #ff7401,
  #febd15,
  #8fdc35,
  #58d53e,
  #29ea4e,
  #30e191,
  #1fe0ba,
  #02cdf8,
  #0077ff,
  #0219fe,
  #6900fe,
  #a300ff,
  #ff00ff,
  #fe00a1,
  #ff0246,
  #ff1900
);

:root {
  @for $i from 1 through list.length($colors) {
    $c: list.nth($colors, $i);
    --r#{$i - 1}: #{$c};
  }
}

@for $i from 0 through list.length($colors) {
  .r#{$i} {
    color: var(--r#{$i});
  }
  .b--r#{$i} {
    border-color: var(--r#{$i});
  }
  .bg-r#{$i},
  .priority.bg-r#{$i} {
    background-color: var(--r#{$i});
  }
}
.priority.fg-white {
  color: white;
}

.r {
  color: var(--body-th);
}

.b--r {
  border-color: var(--body-th);
}

.bg-r {
  background-color: var(--body-th);
}
