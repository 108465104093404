// .blink {
//   animation: 2s blink ease-in-out infinite;
// }
// .throb {
//   animation: 1.5s throb ease-in-out infinite;
// }
// .flicker {
//   animation: 1.5s flicker ease-in-out infinite;
// }
// // keyframes
// @keyframes fadein {
//   from,
//   to {
//     opacity: 0.5;
//   }
//   50% {
//     opacity: 1;
//   }
// }
// @keyframes fadeout {
//   from,
//   to {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.5;
//   }
// }
// @keyframes blink {
//   from,
//   to {
//     color: inherit;
//   }
//   50% {
//     color: transparent;
//   }
// }
// @keyframes flicker {
//   0% {
//     opacity: 1;
//   }
//   10% {
//     opacity: 0.25;
//   }
//   20% {
//     opacity: 0.1;
//   }
//   30% {
//     opacity: 0.75;
//   }
//   40% {
//     opacity: 0.5;
//   }
//   50% {
//     opacity: 0.25;
//   }
//   60% {
//     opacity: 0.55;
//   }
//   70% {
//     opacity: 1;
//   }
//   80% {
//     opacity: 0.25;
//   }
//   90% {
//     opacity: 0.55;
//   }
//   100% {
//     opacity: 1;
//   }
// }
// @keyframes throb {
//   from,
//   to {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.25;
//   }
// }
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin75 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin50 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin25 {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
