@use 'vars';

/*
 * THIS FILE IS USED BY THE CORPERATE SITE
 */

//menu colors are in _action.scss
.menu {
  list-style: none;
  position: relative;
  border-radius: vars.$rounding-radius;
  // necessary to properly get offset of menu dropdown
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  .menu-head {
  }
  .menu-items {
    display: none;
    text-align: left !important;
    position: absolute;
    top: 2.05rem;
    &:not(.right) {
      left: 0;
    }
    &.right {
      right: 0;
    }
    margin: auto 0;
  }
  .menu-item {
    display: block;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
  }
  &:hover > .menu-items {
    display: inherit;
  }
  .menu-items.left {
    right: auto;
    left: 0;
    margin: 0 auto;
  }
}

// auto menu adjustments
button.menu {
  border: none !important;
  box-shadow: none;
  outline: none;
  &::after {
    // @extend .theme-fg-minimize;
    content: '\f107';
    font-family: 'Font Awesome 5 Free';
    margin-left: 0.5rem;
    &[aria-expanded='true'] {
      content: '\f106';
    }
  }
}
button[aria-expanded='true'].menu::after {
  content: '\f106';
}

.base-navbar .desktop-header {
  .menu {
    visibility: hidden;
    transform: translateX(-25%);
    border: solid 1px var(--alpha-minimize25);
  }
  & + .menu:hover,
  &:hover + .menu {
    visibility: visible;
  }
}

.hamburger-menu {
  appearance: none;
  -webkit-appearance: none;
  &::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f0c9';
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
    font-size: 1.25rem;
    cursor: pointer;
  }
  &:checked::after {
    content: '\f00d';
  }

  &:not(:checked) ~ .mobile-menu {
    display: none;
  }
}
