.notifier {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(var(--navbar-height) * 2);
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.msg {
  background-color: var(--body);
  @media screen and (min-width: 48em) {
    max-width: 32rem;
  }
  @media screen and (min-width: 60em) {
    max-width: 40rem;
  }
}

.error {
  border-color: var(--error);
  color: var(--error);
}

.warn {
  border-color: var(--warn);
  color: var(--warn);
}

.info {
  border-color: var(--info);
  color: var(--info);
}
