.videoBox {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  video {
    object-fit: fill;
    overflow: hidden;

    /*
    challenge: get it to be as high resolution as possible by not just relying
    on object-fit: cover. to do this we need to understand the aspect ratio
    plus the width or height, and switch it to fit to either height or with,
    with the other as auto, so it'll not have white space showing.  This is
    not easy.
    */
    // height: calc(100vh - var(--navbar-height) * 3);
    height: auto;
    width: 100%;
    @media screen and (orientation: portrait) {
      height: 100%;
      width: auto;
    }
    // @media screen and (orientation: landscape) and (max-width: 1250px) {
    //   height: 100%;
    //   width: auto;
    // }
    // // z-index: -2;
  }
}
