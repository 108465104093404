@use 'vars';

input:not([type='checkbox'], [type='radio']),
textarea,
select {
  padding: 0.5rem;
  &::placeholder {
    font-style: italic;
    font-weight: 200;
    font-size: smaller;
    color: gray;
  }
  border-radius: vars.$rounding-radius;
  // border-style: solid;
  // border-width: 1px;
}

input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  color: inherit;
  &::after {
    font-family: 'Font Awesome 6 Free';
    content: '\f0c8';
    vertical-align: middle;
    cursor: pointer;
  }
  &:disabled::after {
    cursor: default;
  }
  &:checked::after {
    content: '\f14a';
  }
}

input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  color: inherit;
  &::after {
    font-family: 'Font Awesome 6 Free';
    content: '\f111';
    vertical-align: middle;
    cursor: pointer;
  }
  &:disabled::after {
    cursor: default;
  }
  &:checked::after {
    content: '\f192';
  }
}

// on Chrome the native input=date calendar icon doesn't respect the theme change
input[type='date']::-webkit-calendar-picker-indicator {
  background-image: url('./svg/dark-calendar.svg');
  cursor: pointer;
  .theme-dark & {
    background-image: url('./svg/light-calendar.svg');
  }
}

.input-inline {
  position: relative;
  .mark {
    background-color: var(--alpha-maximize85);
    right: 0.125rem;
    position: absolute;
  }
  textarea {
    resize: none;
    overflow: hidden;
    width: 100%;
  }
  &:not(.normal) textarea {
    border-radius: 0;
    box-sizing: border-box;
    display: block;
    height: auto;
    margin: inherit;
    outline: none;
    padding: 0.25rem;
    &.padded {
      padding: 1rem;
    }
    &.dirty {
      border-color: var(--warn);
    }
    &.failed {
      border-color: var(--error);
    }
  }
}
