$controls-icon-height: 1.75rem;
$controls-gutter: 0.5rem;
$controls-offset: 0.5rem;
// cross-reference w/Modal
// $controls-height: $controls-icon-height + ($controls-offset * 2);
.controls {
  position: absolute;
  top: $controls-offset;
  right: $controls-offset;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  &.reverse {
    flex-direction: row-reverse;
  }
}
.control {
  &.header {
    color: gray;
    // margin-left: $controls-gutter;
    border-radius: $controls-gutter;
  }
  &.inline {
    color: white;
    border-radius: $controls-icon-height;
  }
  height: $controls-icon-height;
  width: $controls-icon-height;
  &:hover {
    background-color: gray;
    color: white;
  }
  position: relative;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
