$cursors: auto, default, not-allowed, wait, grab, grabbing, help, move,
  context-menu;

@each $cursor in $cursors {
  .cursor-#{$cursor} {
    cursor: #{$cursor};
  }
}

.cursor-grab:active:hover {
  cursor: grabbing;
}

$selects: none, auto, text, contain, all;

@each $select in $selects {
  .user-select-#{$select} {
    user-select: #{$select};
  }
}
