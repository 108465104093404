// .loading-inline {
//   margin-left: 1em;
//   width: 1em;
//   height: 1em;
//   border-radius: 3em;
//   animation: spin 1s linear infinite;
//   border-top: 0.5em solid rgba(0, 0, 0, 0.2);
//   border-right: 0.5em solid rgba(0, 0, 0, 0.2);
//   border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
//   border-left: 0.5em solid #ffffff;
// }
$size1: 2px;
$size2: 4px;
$width: 1px;
.loading-inline {
  &:not(.ml1) {
    margin-left: 1em;
  }
  width: 1em;
  height: 1em;
  border: $width solid transparent;
  border-radius: 100%;
  border-top-color: var(--hilite);
  border-right-color: var(--hilite);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 5s linear infinite;
}
.loading-inline:before {
  content: '';
  position: absolute;
  top: $size1;
  left: $size1;
  right: $size1;
  bottom: $size1;
  border-radius: 100%;
  border: $width solid transparent;
  border-top-color: var(--body-th);
  border-right-color: var(--body-th);
  -webkit-animation: spin 1.75s linear infinite;
  animation: spin 3s linear infinite;
}
.loading-inline:after {
  content: '';
  position: absolute;
  top: $size2;
  left: $size2;
  right: $size2;
  bottom: $size2;
  border-radius: 100%;
  border: $width solid transparent;
  border-top-color: var(--body-action);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

/* loading spinner */
.loading-overlay {
  z-index: 2147483647;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.7);
  fill: rgba(0, 0, 0, 0.7);
}
.loading,
.loading-body {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 60vw;
  height: 60vw;
  margin: -30vw 0 0 -30vw;
  @media screen and (min-width: 30em) {
    width: 30vw;
    height: 30vw;
    margin: -15vw 0 0 -15vw;
  }
}
.loading-body {
  color: white;
  text-align: center;
  top: 45%;
}
$size1: 5px;
$size2: 10px;
$width: 3px;
.loading {
  border-radius: 100%;
  border: $width solid transparent;
  border-top-color: var(--hilite);
  -webkit-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
.loading:before {
  content: '';
  position: absolute;
  top: $size1;
  left: $size1;
  right: $size1;
  bottom: $size1;
  border-radius: 100%;
  border: $width solid transparent;
  border-top-color: var(--body-th);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loading:after {
  content: '';
  position: absolute;
  top: $size2;
  left: $size2;
  right: $size2;
  bottom: $size2;
  border-radius: 100%;
  border: $width solid transparent;
  border-top-color: var(--body-action);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-leave {
  opacity: 1;
}
.loading-leave.loading-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
