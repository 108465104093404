@use "cato/vars";

@import url('https://fonts.googleapis.com/css2?family=Allerta&display=swap');

/*
Console specific variants
*/
[data-lastpass-root=''] {
  display: none;
}
#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  // .main {
  //   flex: 1 0 auto;
  // }
}

.font-slashed {
  // variable width font with slashed 0s
  font-family: 'Roboto Mono', sans-serif;
}
code,
.font-mono {
  font-family: 'Roboto Mono';
}

.base-navbar {
  a,
  button {
    color: var(--navbar-text);
    background-color: inherit;
    border: solid 1px;
    border-color: transparent;
    // tabs get a bigger rounding
    border-radius: vars.$rounding-radius * 2;
    &.active {
      color: var(--navbar-action);
    }
    &:hover {
      background-color: var(--navbar-action-hover);
      color: var(--navbar-action-hover-text);
    }
    &.navtab {
      padding: 0.25rem 0.5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &:not(.active) {
        background-color: var(--alpha-maximize10);
      }
      &.active {
        // background-color: var(--hilite);
        color: var(--body-action-text);
        &:hover {
          background-color: var(--hilite-action-hover);
        }
      }
    }
  }
  &.navmenu {
    width: max-content;
    height: 100%;
    padding-bottom: 0.25rem;
    a {
      display: block;
    }
    .menu-item {
      padding: 0.5rem 1rem;
      width: 100%;
    }
  }
}

// should hrstrike be in tachyons-field? -BJG
.hrstrike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.hrstrike > div {
  position: relative;
  display: inline-block;
}
.hrstrike > div:before,
.hrstrike > div:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #999;
}
.hrstrike > div:before {
  right: 100%;
  margin-right: 15px;
}
.hrstrike > div:after {
  left: 100%;
  margin-left: 15px;
}

.theme-tab {
  background-color: var(--frame);
  &:not(.active) {
    background-color: var(--frame-inactive);
    color: var(--frame-inactive-text);
  }
  &:hover {
    background-color: var(--frame-hover);
  }
}

.hoverbox {
  margin: -1rem;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  &:hover {
    // background: var(--body);
    border-color: var(--alpha-minimize25);
  }
  .hbox-contents {
    margin: 1rem;
  }
}

.center-x {
  left: 50%;
  transform: translateX(-50%);
}
.center-y {
  top: 50%;
  transform: translateY(-50%);
}
.center-xy {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subgrid-full {
  display: grid; // grid
  grid-template-columns: subgrid; // subgrid
  grid-column: 1/-1; // span-all
}

// future: this is coming in tachyons-field
.pill {
  border-radius: 1rem !important;
  border-width: 1px !important;
}
