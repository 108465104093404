// Future investigation.  http://css3.bradshawenterprises.com/cfimg/
// consider using img tags at a low layer
.background {
  // @media (orientation: portrait) {
  //   height: 100vh;
  //   width: auto;
  // }
  // @media (orientation: landscape) {
  //   height: auto;
  //   width: 100vw;
  // }
  // position: fixed;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // z-index: -1;
  overflow: hidden;
  // animation: bgAnimate 20s linear infinite;

  // background: url(http://www.gstatic.com/webp/gallery/1.jpg) 0% 0% cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: inherit;
  background-position: center center;
  background-size: cover;
  margin: 0;
  /*
  -webkit-animation: animateBg 20s linear infinite;
  -webkit-animation-name: animateBg;
  -webkit-animation-duration: 100s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
   */
  @media screen and (min-width: 30em) {
    &.img-pulse {
      animation: grow-shrink 60s infinite;
    }
  }
}

.bg-img {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-img-fixed {
  @extend .bg-img;
  background-attachment: fixed;
}
