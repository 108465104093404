// ul.less {
//   list-style: none;
//   li::before {
//     content: '\00b7';
//     font-weight: 800;
//     color: gray;
//     display: inline-block;
//     width: 1em;
//     margin-left: -0.9em;
//   }
// }

.list-inside {
  list-style-position: inside;
}
