.subtle-scrollbars {
  $color: #656565;
  scrollbar-color: $color transparent;
  &::-webkit-scrollbar {
    height: 8px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $color;
  }
}
