.back {
  $size: 2rem;
  $margin: 1rem;
  $breakpoint-large: 62rem;
  $breakpoint: #{$breakpoint-large + $size + $margin + $margin};
  $breakout: 'screen and (min-width: #{$breakpoint})';
  @media #{$breakout} {
    // break point max-view-page
    margin-left: -#{$size + $margin};
  }
  a {
    min-width: $size;
    max-width: $size;
    min-height: $size;
    max-height: $size;
  }
}
