.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--alpha-minimize15);
  backdrop-filter: blur(0.25px);
}
.scroll {
  height: calc(100vh - 10rem) !important;
  max-height: calc(100vh - 10rem);
}

.modal {
  .frame {
    position: absolute;
    top: 2rem;
    max-height: calc(100vh - 3rem);
    left: 0;
    right: 0;
    &.fw-50 {
      @media screen and (min-width: 30rem) {
        top: 4rem;
        left: 15%;
        right: 15%;
        max-height: calc(100vh - 5rem);
      }
      @media screen and (min-width: 60rem) {
        top: 6rem;
        left: 25%;
        right: 25%;
        max-height: calc(100vh - 7rem);
      }
      @media screen and (orientation: landscape) and (min-width: 30rem) {
        top: 2rem;
        max-height: calc(100vh - 3rem);
      }
      @media screen and (orientation: portrait) and (min-width: 60rem) {
        top: 2rem;
        max-height: calc(100vh - 3rem);
      }
    }
    &.fw-75 {
      @media screen and (min-width: 30rem) {
        left: 5%;
        right: 5%;
      }
      @media screen and (min-width: 60rem) {
        left: 12%;
        right: 12%;
      }
    }
    &.fw-95 {
      @media (min-width: 60rem) {
        // @media screen and (min-width: var(90rem)) {
        margin-left: auto;
        margin-right: auto;
        max-width: 55rem;
      }
      @media screen and (min-width: 30rem) {
        left: 1rem;
        right: 1rem;
      }
      @media screen and (min-width: 60rem) {
        left: 2rem;
        right: 2rem;
      }
    }
    &.fw-min {
      @extend .fw-50;
      top: 25%;
      bottom: auto;
      max-height: calc(100vh - 25% - 1rem);
    }
    &.fw-shrink {
      @extend .fw-50;
      top: 25%;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      bottom: auto;
      max-height: calc(100vh - 25% - 1rem);
    }
    &.fw-max {
      @media (min-width: 60rem) {
        left: 1rem;
        right: 1rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 55rem;
      }
    }

    // box-shadow: 5px 5px 15px var(--t-gray);
    // border: solid 2px var(--t-solidbg-dark);
    // background-color: var(--t-gray);
    // backdrop-filter: blur(10px);
    // color: var(--t-primary);
    .body {
      // padding-left: 0.5rem;
      padding: 0;
    }
    // .close {
    //   cursor: pointer;
    //   border: solid 2px var(--t-solidbg-dark);
    //   position: absolute;
    //   background-color: var(--t-primary);
    //   height: calc(1.25rem - 1px);
    //   width: calc(1.25rem - 1px);
    //   top: -0.7rem;
    //   right: 0;
    //   z-index: +1;
    //   @media screen and (min-width: 30rem) {
    //     right: -0.7rem;
    //   }
    //
    //   color: white;
    //   &:hover {
    //     background-color: var(--t-primary-hilite);
    //   }
    .close {
      cursor: pointer;
      position: absolute;
      height: calc(1.25rem - 1px);
      width: calc(1.25rem - 1px);
      top: 0.5rem;
      right: 0;
      @media screen and (min-width: 30rem) {
        right: 0.5rem;
      }
    }
  }
}
.header {
  // cross-reference with base-frame radius
  //border-top-left-radius: 0.5rem;
  //border-top-right-radius: 0.5rem;
  // cross-reference with tools/Modal
  height: 2.25rem;
  width: 100%;
}
