table {
  border-spacing: 0;
  border-collapse: collapse;
}

table.alternating {
  tr:nth-child(even) {
    background-color: var(--alpha-minimize05);
    fill: var(--alpha-minimize05);
    // td:nth-child(1) {
    //   border-top-left-radius: 0.5rem;
    //   border-bottom-left-radius: 0.5rem;
    // }
    // td:nth-last-child(1) {
    //   border-top-right-radius: 0.5rem;
    //   border-bottom-right-radius: 0.5rem;
    // }
  }
}

.row-hover:hover {
  background-color: var(--alpha-minimize25);
  fill: var(--alpha-minimize25);
}

table.plain th {
  background-color: var(--neutral);
}

table.clear th {
  background-color: transparent;
}

table.list {
  tr:not(.no-hover):hover {
    > td {
      background-color: var(--alpha-minimize10);
      fill: var(--alpha-minimize10);
    }
    // td:nth-child(1) {
    //   border-top-left-radius: 0.5rem;
    //   border-bottom-left-radius: 0.5rem;
    // }
    // td:nth-last-child(1) {
    //   border-top-right-radius: 0.5rem;
    //   border-bottom-right-radius: 0.5rem;
    // }
  }
  th {
    text-align: left;
    //font-weight: inherit;
    font-size: smaller;
    // padding-left: 0.5rem;
  }
  // td {
  //   padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  // }
}

table.report {
  td,
  th {
    padding: 0.25rem 0.5rem;
  }
}
